import IconBuchung from '@assets/page-icons/buchungssystem.svg?raw';
import IconKursverwaltung from '@assets/page-icons/kursverwaltung.svg?raw';
import IconSeminarverwaltung from '@assets/page-icons/seminarverwaltung.svg?raw';

import IconCamps from '@assets/icons/icon-camps.svg?raw';
import IconFahr from '@assets/icons/icon-fahr.svg?raw';
import IconFuhrungen from '@assets/icons/icon-fuhrungen.svg?raw';
import IconHerste from '@assets/icons/icon-herste.svg?raw';
import IconJag from '@assets/icons/icon-jag.svg?raw';
import IconKongress from '@assets/icons/icon-kongress.svg?raw';
import IconKosmetik from '@assets/icons/icon-kosmetik.svg?raw';
import IconSchwim from '@assets/icons/icon-schwim.svg?raw';
import IconSegel from '@assets/icons/icon-segel.svg?raw';
import IconSeminare from '@assets/icons/icon-seminare.svg?raw';
import IconSprach from '@assets/icons/icon-sprach.svg?raw';
import IconTickets from '@assets/icons/icon-tickets.svg?raw';

export type NavigationMenuListItem = {
    title: string;
    link?: string;
    subtitle?: string;
    icon?: any;
    iconColor?: string;
    children?: Omit<NavigationMenuListItem, 'children'>[];
};

export const menuList: NavigationMenuListItem[] = [
    {
        title: 'Lösungen',
        children: [
            {
                title: 'Kursverwaltung',
                icon: IconKursverwaltung,
                // iconColor: '#2B57C8C7',
                link: '/kursverwaltung',
            },
            {
                title: 'Seminarverwaltung',
                icon: IconSeminarverwaltung,
                // iconColor: '#39B54A',
                link: '/seminarverwaltung',
            },
            {
                title: 'Buchungssystem',
                icon: IconBuchung,
                // iconColor: '#ED2177CF',
                link: '/buchungssystem',
            } /*
            {
                title: 'Buchungssoftware',
                icon: IconBuchung,
                iconColor: '#ED2177CF',
                link: '/buchungssoftware',
            },
            {
                title: 'Kursbuchungssystem',
                icon: IconKursverwaltung,
                iconColor: '#2B57C8C7',
                link: '/kursbuchungssystem',
            },*/,
            /*{
                title: 'Kurssoftware',
                icon: IconKursverwaltung,
                iconColor: '#2B57C8C7',
                link: '/kurssoftware',
            },*/
            /*{
                title: 'Seminarsoftware',
                icon: IconKongress,
                iconColor: '#2B57C8C7',
                link: '/seminarsoftware',
            },
            {
                title: 'Seminarbuchungssystem',
                icon: IconKongress,
                iconColor: '#2B57C8C7',
                link: '/kurssoftware',
            },*/
        ],
    },
    {
        title: 'Branchen',
        children: [
            {
                title: 'Schulungen, Seminare & Trainings',
                icon: IconSeminare,
                link: '/branchen/seminare',
                iconColor: '#F3227AD6',
            },
            {
                title: 'Kongresse & Tagungen',
                icon: IconKongress,
                link: '/branchen/kongresse',
                iconColor: '#2B57C8DB',
            },
            {
                title: 'Führungen',
                icon: IconFuhrungen,
                link: '/branchen/fuehrungen',
                iconColor: '#FBB03A',
            },
            {
                title: 'Freizeitaktivitäten & Camps',
                icon: IconCamps,
                link: '/branchen/camps',
                iconColor: '#9F40D9',
            },
            {
                title: 'Event Tickets',
                icon: IconTickets,
                link: '/branchen/event-tickets',
                iconColor: '#39B54A',
            },
            {
                title: 'Erste-Hilfe-Kurse',
                icon: IconHerste,
                link: '/branchen/erste-hilfe-kurse',
                iconColor: '#2DADB8',
            },
            {
                title: 'Segelschulen',
                icon: IconSegel,
                link: '/branchen/segelschulen',
                iconColor: '#F7864D',
            },
            {
                title: 'Schwimmschulen',
                icon: IconSchwim,
                link: '/branchen/schwimmschulen',
                iconColor: '#2DADB8',
            },
            {
                title: 'Sprachschulen',
                icon: IconSprach,
                link: '/branchen/sprachschulen',
                iconColor: '#4D7DF7',
            },
            {
                title: 'Kosmetikschulen',
                icon: IconKosmetik,
                link: '/branchen/kosmetikschulen',
                iconColor: '#ED2177D6',
            },
            {
                title: 'Jagdschulen',
                icon: IconJag,
                link: '/branchen/jagdschulen',
                iconColor: '#FBB03A',
            },

            {
                title: 'Fahrschulen',
                icon: IconFahr,
                link: '/branchen/fahrschulen',
                iconColor: '#4374B5',
            },
        ],
    },
    // {
    //     title: 'Unternehmen',
    //     link: '/unternehmen',
    // },
    /*{
        title: 'Lernhaus',
        link: '/lernhaus/veranstaltungseinstellungen-ubertragen',
    },*/
    {
        title: 'Kontakt',
        link: '#contact',
    },
];
